/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('../../fonts/montserrat-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../fonts/montserrat-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../../fonts/montserrat-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../fonts/montserrat-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../../fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../fonts/montserrat-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@import '../../vendor/swiper/css/swiper.min';

$base-font-sans: Montserrat, Helvetica, Arial, sans-serif;

$color-primary: rgb(0, 132, 126);
$color-primary--light: mix($color-primary, white, 10%);
$color-primary--medlight: mix($color-primary, white, 25%);
$color-primary--medium: rgb(84, 173, 169);

$color-gray: rgb(207, 207, 207);

// RESET & NORMALIZATION
*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  list-style: none;
}

article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section, summary {
  /* Add correct display for IE 9- and some newer browsers */
  display: block;
}

::selection {
  background-color: $color-primary;
  color: white;
}

::-moz-selection {
  background-color: $color-primary;
  color: white;
}


html {
  background-color: $color-primary--light;
  color: black;
  font: normal 300 1.25em/1.5 $base-font-sans;
  overflow-x: hidden;
  overflow-y: scroll;
  cursor: default;
  position: relative;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* Highlight color for WebKit-bases touch devices. */
  -webkit-tap-highlight-color: rgba(113, 239, 172, .5);
}

@media all and (max-width: 30em) {
  html {
    /* We decrease the font-size a little-bit for tiny
       screens. Because this stylesheet uses relative units
       everywhere, everything scales nicely. */
    font-size: 1.25em;
  }
}

body {
  margin-bottom: 0;
}

// .main {
//   padding: 0;
// }


h1, h2, h3, h4, h5, h6,
p, ul, ol, dl, figure, pre {
  /* Reset font-sizes and normalize margins */
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 1.5rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $base-font-sans;
  font-weight: 400;
  text-transform: uppercase;
}
h1, .h1,
h2, .h2 {
  border-bottom: 1px solid black;
}
h1, .h1 {
  font-size: 1.25em;
  margin: 3.5rem 0 2.5rem;
}
h2, .h2 {
  font-size: 1.25em;
}
h3, .h3 {
  font-size: 1em;
}
h4 {}

.no-border {
  border-bottom: 0;
}

.hl-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  h1,
  h2 {
    display: inline-block;
  }
}

a {
  color: $color-primary;
  text-decoration: underline;
  border-bottom: 0;
  &:focus, &:active {
    color: inherit;
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

em {
  color: $color-primary;
  font-style: normal;
  font-weight: 300;
}

img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

figcaption {
  font-size: .75rem;
  line-height: 1.5rem;
}








// GRID & LAYOUT
.flex-grid {
  display: flex;
  flex-flow: row wrap;
}
.column.half {
  width: 50%;
}
.wrap {
  box-sizing: content-box;
  margin: 0 auto;
  padding-left: .75rem;
  padding-right: .75rem;
  max-width: 40rem; // ~960px
}
.wrap.wide {
  max-width: 46rem; // ~1200px
}

.wrap.full-width {
  max-width: none;
}

@media all and (min-width: 31.5em) {
  .wrap:not(.wide) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media all and (min-width: 38.25em) {
  .wrap {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .wrap.wide {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.grid {
  font-size: 0;
}
// increase gutter
.grid.gutter-1 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}
.grid.gutter-1 .column {
  padding-left: .75rem;
  padding-right: .75rem;
}
.column {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  vertical-align: top;
}


// HELPERS

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }


// HEADER
.header {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
  border-top: .5em solid $color-primary--medium;
  .flex-grid {
    justify-content: space-between;
  }
  .branding { // TODO: CHECK
    width: 7.5rem;
    margin-right: 1rem;
    a {
      border-bottom: 0;
    }
  }
  .navigation {
    width: auto;
    margin-top: 1.5rem;
    text-transform: uppercase;
  }
  .navigation .menu {
    margin-bottom: 0;
  }
}

// footer
.footer {
  padding: 1rem 0;
  font-size: .8em;
}
.nav {
  ul {
    margin: 0;
  }
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

// SECTIONS
section { overflow: hidden; }
.min-vp-height {
  min-height: 100vh;
}

.section {
  padding-bottom: 3rem;
}

.home-section {
  padding-bottom: 3rem;
}

.figures-section {
  // min-height: 100vh;
  overflow: hidden;
  padding: 3em 0;
  background-color: $color-gray;
  h1, h2 { margin-top: 0; }
}

// .neutral-bg {
  // background-color: $color-gray;
// }

.services-section,
.expertinfo-section {
  // margin-top: 1.5rem;
  min-height: 50vh;
  padding-top: 3rem;
  padding-bottom: 1rem;
  background-color: $color-primary--light;
  .service__headline {
    text-transform: uppercase;
  }
  &:nth-child(2n+1) {
    background-color: $color-primary--medlight;
  }
}
// TODO: REMOVE ID SELECTOR
#aerzte {
  background-color: $color-primary--medlight;
}

.doctor-section {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  &:nth-child(2n+1) {
    background-color: $color-primary--light;
  }
}

@media screen and (max-width: 48rem) {
  // reset grid
  .doctor-section,
  .service,
  .main.contact,
  .main.legal,
  .staff {
    .grid .column {
      display: block;
      width: auto;
    }
  }
  .service .text > .grid > .column {
    margin-bottom: 1em;
  }
  .doctor-section {
    .doctor__image {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}



// MAIN CONTENT
.text {
  p,
  ul,
  dl,
  ol {
    font-size: .9rem;
  }
  p {
    margin-bottom: .9rem;
  }
  ul {
    list-style: none;
    margin-left: 1em;
  }
  ul > li::before {
    float: left;
    position: relative;
    left: -1.25em;
    width: 1.25em;
    margin-right: -1.25em;
    padding-left: .25em;
    color: $color-primary;
    content: '\00BB';
  }
  ul > li ul > li:before {
    float: left;
    position: relative;
    left: -1.25em;
    width: 1.25em;
    margin-right: -1.25em;
    padding-left: .25em;
    color: $color-primary;
    content: '\2013';
  }
  ol > li {
    list-style: decimal;
  }
}
nav {
  ul > li::before {
    content: none;
  }
}


.menu {
  font-size: .9em;
  font-weight: 300;
  .menu-item {
    display: inline-block;
    &:not(:last-child)::after {
      content: none
    }
    &:not(:last-child) {
      margin-right: 1em;
    }
    &.is-active a {
      font-weight: 700;
      color: $color-primary;
      border: 0;
    }
  }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: $color-primary;
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 6rem;
    .branding { margin-right: 0; }
    .navigation { margin-top: 0; }
  }
  .navigation {
    width: 100%;
    max-height: 0;
    margin: 0;
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    z-index: 100;
    transition: max-height .4s ease;
    overflow: hidden;
    .menu {
      text-align: left;
      font-size: 1.25em;
    }
    .menu-item {
      width: 100%;
      margin: 0;
      padding: 1rem .75rem;
      background-color: $color-primary;
      color: white;
      &:not(:last-child) {
        margin-right: 0;
        border-bottom: 1px solid white;
      }
      a {
        display: block;
        color: inherit;
        &:hover {
          font-weight: 700;
        }
      }
      &.is-active {
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
  }
}
@media screen and (min-width: 38.25em) and (max-width: 46.875em) {
  .navigation .menu-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (min-width: 750px) {
  .menu-toggle {
    display: none;
  }
}

.menu-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  outline: 0;

  width: 3rem;
  height: 3rem;
  margin-top: .25rem;
  position: relative;
  text-indent: -9999px;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  transition: opacity .3s ease;

  &:hover,
  &:focus {
    span {
      opacity: .8;
    }
  }

  span,
  span:before,
  span:after {
    display: block;
    position: absolute;
    width: 2rem;
    height: 5px;
    background-color: $color-primary;
  }

  span {
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  span:before,
  span:after {
    left: 0;
    content: "";
  }

  span:before { top: -.75rem; }
  span:after { bottom: -.75rem; }
}


// animation for .menu-toggle
.menu-toggle {

  span {
    transition: background 0s 0.3s;
  }

  span:before,
  span:after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
  }

  span:before {
    /* autoprefixer: off */
    transition-property: top, -webkit-transform;
    transition-property: top, transform;
  }

  span:after {
    /* autoprefixer: off */
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
  }
}

.menu-toggle.is-active {
  span { background: none; }

  span:before {
    top: 0;
    transform: rotate(45deg);
  }

  span:after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  span:before,
  span:after { transition-delay: 0s, 0.3s; }
}

.btn {
  display: inline-block;
  padding: 1em;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: .9em;
  font-weight: 300;
  line-height: 1;
  border: 1px solid black;
  color: black;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  &:hover,
  &:focus,
  &:active {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
}

.btn--plain {
  display: inline-block;
  border: 0;
  padding: .6em .75em;
  white-space: nowrap;
  font-size: .75em;
  line-height: 1;
  font-weight: 700;
  background: white;
  border-color: white;
  color: black;
  cursor: pointer;

  &:hover, &:focus, &:active {
    background: gray;
    border-color: gray;
  }
}

.btn-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  li {
    margin: 0 .5rem;
  }
  .btn {
    min-width: 9rem;
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 64em) {
  .multi-column {
    column-count: 2;
    column-gap: 1em;
  }
}

.accordion {
  .accordion__item input[type="checkbox"]:checked ~ .accordion__content {
    max-height: none;
  }
}
.accordion__item {
  p {
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    display: none;
  }
  &.has-details label {
    color: $color-primary;
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      color: black;
    }
  }
}
.accordion__item.has-details .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s ease;
}
.accordion__content {
  ul {
    margin-left: 1em;
    margin-bottom: 0;
  }
}


// doctors
.doctor__description {
  // font-size: .9rem;
  p:last-child {
    margin-bottom: 0;
  }
}

// employees
.employees {
  $margin: .75em;
  display: flex;
  flex-flow: row wrap;
  .employee {
    width: calc( 33% - #{$margin / 3 * 2} );
    // width: auto;
    // max-width: 100%;
    height: auto;
    margin: 0 $margin $margin 0;
  }
}

.employees .employee:nth-child(3n + 3) {
  margin-right: 0;
}

.swiper-container {
}
// IE fix
.swiper-slide {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;' // [1]
  }
}
.swiper-pagination {
  text-align: center;
}
.swiper-pagination-bullet {
  width: .75em;
  height: .75em;
  background-color: white;
  opacity: .6;
  transition: opacity .3s ease;
}
.swiper-pagination-bullet-active {
  opacity: 1;
}

@media screen and (min-width: 37em) { // 600
  .swiper-pagination {
    bottom: 1em;
    padding-right: 1em;
    text-align: right;
  }
  .swiper-pagination-bullet {
    width: 1em;
    height: 1em;
  }
}

@media screen and (min-width: 64em) { // 1024
  .swiper-slide {
    img {
      max-height: 65vh;
    }
  }
}
.key-value-list {
  display: flex;
  flex-flow: row wrap;
  dt { width: 25%; }
  dd { width: 75%; }
}
@media screen and (min-width: 48rem ) {
  .contact-list {
    .mail {
      float: none;
    }
  }
}

// BREADCRUMB
.breadcrumb {
  padding-top: 1em;
  font-size: .8em;
  text-transform: uppercase;
  ul { margin-bottom: 0; }
  li {
    display: inline-block;
    &:after {
      display: inline-block;
      content: '>';
      margin: 0 .5em;
    }
  }
  li:last-child {
    &:after { content: none }
  }
  .is-active {
    color: $color-primary;
  }
}

.to-top {
  font-size: .8em;
}

// page specific styles
.service {
  background-color: $color-gray;
}
.staff {
  background-color: $color-primary--medlight;
}

.doctor-detail {
  padding-top: 3rem;
  h1,
  h2 {
    text-align: left;
  }
}

.map {
  min-height: 16rem;
  margin-bottom: 3rem;
}

.subpage-nav {
  margin-top: 2.5rem;
}

.template-expertinfo .section {
  padding-bottom: 0;
}
